.project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .project h1 {
    margin-top: 15px;
    color: #3e497a;
    font-size: 35px;
    margin-bottom: 15px;
  }
  .project video {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 50px;
  }
  
  .project p {
    font-size: 35px;
    color: #3e497a;
  }
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }

 


  @media (max-width:768px){
    .project p {
      font-size: 16px;
    }
  }