.icons{
    font-size: 25px;
}

a.navbar-brand {
  letter-spacing: 3px;
  color: black;
}

a.navbar-brand:hover {
	color: #c24244;
}

.navbar-scroll .nav-link,
.navbar-scroll .fa-bars {
  color: black;
} 


.navbar-scrolled .fa-bars {
  color: #7f4722;
}

.navbar-scrolled .nav-link{
  color:#c24244 ;
}

.navbar-scrolled {
  background-color: #ffede7;
}

nav{
    top:0;
    /* box-shadow: 0px 13px 24px 0px rgba(0,0,0,0.08); */
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;
}
nav .navbar-collapse.collapse{
    display: block;
}
@media (max-width: 991px){
    nav .navbar-collapse.collapse{
        overflow: hidden;
    }
    nav .navbar-collapse.active{
        height: 38vh !important;
        background:transparent;
    }
}

nav .navbar-collapse{
    transition: .6s all ease-in-out;
    height: 0 !important;
}

