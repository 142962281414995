.interests .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    /* background: rgba(122, 122, 122, 0.08); */
    transition: ease-in-out 0.6s;
  }
  
  .interests .icon-box i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 1;
    color: black;
  }
  
  .interests .icon-box h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    color: #7f7066;
  }
  /* .icon-box{
    border:1px solid  rgba(80, 63, 205, 0.5)
  } */
  .interests .icon-box:hover {
    /* background: rgba(10, 10, 10, 0.12); */
    border: 1px solid black;
  }

  .section-title{
    margin-top: 20px;
  }
