.footer.footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .footer.footer .social-link {
    border: 2px solid #1A1A1A;
    color: #fff;
    display: block;
    text-decoration: none;
    height: 4rem;
    width: 4rem;
    line-height: 4.3rem;
    font-size: 1.5rem;
    background-color: #1A1A1A;
    transition: background-color 0.15s ease-in-out;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .footer.footer .social-link:hover {
    color:black;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    text-decoration: none;
  }
